import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  larsseit,
  mainBlue,
  mainGray,
  mainWhite,
  transHover,
  screen,
} from "../../components/common/variables";
import Arrow from "../../images/svg/arrow.svg";

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;

  .label {
    color: ${(props) => (props.btn ? mainWhite : mainGray)};
    display: block;
    font-family: ${larsseit};
    font-size: 0.95rem;
    font-weight: 400;
    transition: ${transHover};
    letter-spacing: 0.08rem;
    @media ${screen.small} {
      font-size: 1.25rem;
      white-space: pre;
    }
    @media ${screen.large} {
      font-size: 1.45rem;
      white-space: normal;
    }
  }

  .arrow {
    width: 33px;
    display: block;
    margin-left: 15px;
    @media ${screen.small} {
      width: ${(props) => (props.btn ? `47px` : `33px`)};
    }
    @media ${screen.large} {
      width: 45px;
      margin-left: 21px;
    }

    svg {
      width: 100%;

      .arrow-line {
        stroke: ${(props) => (props.btn ? mainWhite : mainGray)};
        transition: ${transHover};
      }
    }
  }

  @media ${screen.withCursor} {
    &:hover .label {
      color: ${mainBlue};
    }

    &:hover svg .arrow-line {
      stroke: ${mainBlue};
    }
  }
`;

// props use case below
// label - the text next to arrow
// url - page url to navigate to
// btn - checker if it's for <button/> tag
const LabeledArrow = ({ label, url, btn }) => {
  // turn off default a tag functionality if link is for form submit button
  const noNavigate = (e) => {
    e.preventDefault();
  };

  return (
    <Wrapper to={url || `/`} btn={btn} onClick={btn ? noNavigate : null}>
      <span className="label">{label || `LABEL`}</span>
      <span className="arrow">
        <Arrow />
      </span>
    </Wrapper>
  );
};

export default LabeledArrow;
