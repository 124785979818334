import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  mainWhite,
  screen,
  mainBlue,
  mainBlack,
  larsseit,
} from "../components/common/variables";
import { ScrollLocky } from "react-scroll-locky";
import styled from "styled-components";
import LabeledArrow from "../components/labeled-arrow";

const Wrapper = styled.div`
  background: ${mainBlue};
  color: ${mainWhite};
  padding: 0 26px;
  height: calc(100vh - 184px);
  @media ${screen.xsmall} {
    padding: 0 60px;
    height: calc(100vh - 310px);
  }
  @media ${screen.small} {
    padding: 0 80px;
  }
  @media ${screen.medium} {
    padding: 0 7vw;
  }

  .heading {
    font-family: ${larsseit};
    line-height: 1.23;
    font-size: 2.53rem;
    font-weight: 400;
    max-width: 300px;
    @media ${screen.xsmall} {
      font-size: 3.3rem;
      max-width: 500px;
    }
    @media ${screen.small} {
      font-size: 4rem;
      max-width: 560px;
    }
    @media ${screen.medium} {
      font-size: clamp(1.4rem, calc(1.4rem + 3vw), 5.01rem);
      max-width: 600px;
    }
    @media ${screen.large} {
      max-width: 690px;
    }
  }

  .navigate {
    margin: 20px 0 0 0;
    @media ${screen.xsmall} {
      margin: 49px 0 0 0;
    }

    .label {
      color: ${mainWhite};
    }
    .arrow-line {
      stroke: ${mainWhite} !important;
    }

    @media ${screen.withCursor} {
      &:hover .label {
        color: ${mainBlack};
      }
      &:hover .arrow-line {
        stroke: ${mainBlack} !important;
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout colourNavbar={mainBlue} is404Page>
      <SEO title="404: Not found" noIndex />
      <ScrollLocky isolation={false}>
        <Wrapper>
          <div className="inner-wrapper">
            <h3 className="heading">
              Get that handsome face of yours out of our 404 page.
            </h3>

            <div className="navigate">
              <LabeledArrow label="TAKE ME HOME" url="/" />
            </div>
          </div>
        </Wrapper>
      </ScrollLocky>
    </Layout>
  );
};

export default NotFoundPage;
